
export default {
    data() {
        return {
            footer : null,
            alreadyOnRequest : false
        }
    },
    created() {
        this.getFooter();
    },
    mounted() {
        this.getFooter();
    },
    methods: {
        getFooter(){
            if(!this.footer && !this.alreadyOnRequest){
                this.alreadyOnRequest = true;
                this.$axios.get("storyblok/stories/system/mainfooter",{params:{flushcache : 'yes'}}).then(axRes => {
                    this.alreadyOnRequest = false;
                    this.footer = axRes.data.story.content;
                }).catch(axErr => {
                    console.log(axErr.stack);
                })
            }
        }
    },
}
